import Swal from "sweetalert2";
import { get, post } from "../../../helper/api";
import { useLoaderHelper } from "../../../helper/loaderHelper";

export const SelectReckonBook = (tenantId:number,loader:any) => {
    const inputOptions = new Promise((resolve) => {
        get("/reckonBooks",{tenantId},loader).then((result)=>{
            if(result?.status){
                let allBooks:any={}
                result.data.forEach((element:any) => {
                    allBooks[element.id]=element.name
                });
                resolve(allBooks)
            }
            resolve(null)
        }).catch((error)=>{
            resolve(null)
        })
    });
    const validateAndAddBook=(bookId:string)=>{
        post("/reckonBookUpdate",{tenantId,bookId},undefined,loader).then((result)=>{
            if(result?.status){
                console.log("XXD",result)
                Swal.fire({
                    title: 'Your Connection ID',
                    html: `
                    <p>${result.message}</p>
                    <p>Your connection ID is: <strong>${result.data[0].Connection_id}</strong></p>
                  `,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Copy to Clipboard',
                    cancelButtonText: 'Close',
                    preConfirm: () => {
                        // Copy the connection ID to clipboard
                      navigator.clipboard.writeText(result.data[0].Connection_id).then(() => {
                        Swal.fire('Copied!', 'The connection ID has been copied to your clipboard.', 'success');
                      }).catch(err => {
                        Swal.fire('Failed to Copy', 'There was an issue copying the connection ID.', 'error');
                      });
                    }
                  }).then((result) => {
                    // After the popup is closed, reload the page if the user clicked "Copy to Clipboard"
                    if (result.isConfirmed) {
                      window.location.reload();  // Reload the current page
                    }
                  });
            }
            
        }).catch((error)=>{
            
        })
    }
    Swal.fire({
        title: "Loading your Reckon One books, please wait...",
        text: "This may take a few seconds.",
        icon: "info",
        allowOutsideClick: false, // Prevent closing the modal
        didOpen: () => {
            Swal.showLoading();
        }
    });
    inputOptions.then((options:any) => {
        console.log("options",JSON.stringify(options))
        if(options){
            Swal.fire({
                title: "Select a Reckon one Book",
                input: "select",
                inputOptions:options,
                inputPlaceholder: "Select a Book",
                showCancelButton: true,
            }).then((input)=>{
                if(input.isConfirmed){
                    validateAndAddBook(input.value)
                }
                
              }).catch((error)=>{
                console.log("some POP Have Error",error)
              });
        }else{
         Swal.fire({
                title: "Your Reckon One Book ID",
                input: "text",
                inputValue:"",
                inputLabel: "We couldn't find any Reckon One Book ID. Please enter your Reckon One Book ID here.",
                showCancelButton: true,
                showConfirmButton:true,
              }).then((input)=>{
                if(input.isConfirmed){
                    validateAndAddBook(input.value)
                }
                
              }).catch((error)=>{
                console.log("some POP Have Error",error)
              });
        }
    });
}
