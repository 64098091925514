import { Badge, Spinner } from "reactstrap";
import { rightIcon } from "../../constant/icons";

export const FunctionNameLabel = (props: any) => {
    const {column, rowData} = props;
    return rowData?.jobPayload?.map((it:any)=>{
      return <Badge
      className="animate__animated animate__headShake"
      color="success"
      pill
      style={{margin:"0.9px",marginRight:"5px",fontFamily:"sans-serif"}}
    >
      {it.functionType.functionName}
    </Badge>    
   })
};