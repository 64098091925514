import { createContext, useContext, useEffect, useState } from "react";
import { API_URL, get } from "../helper/api";
import { useApi } from "../helper/apiHook";
import { selectValue } from "../helper/types";
import { useNavigate } from "react-router-dom";
import { columnMap } from "../pages/job/type";

export interface FunctionColumn {
    columnId: string;
    columnLabel: string;
    isRequired: boolean;
    requiredInfo?: string,
    exampleInfo?: string,
    jsonMap?: string;
    validation?: any;
    isDefaultAdd?: boolean;
    defaultValue?: string;
}
export interface FunctionSetting {
    serverSidePagination: boolean,
    isBasicMigration?:boolean
}
export interface Function {
    code: string;
    label: string;
    columnName?: string;
    jsonGroupName?: string;
    appCode: string;
    isActivate: boolean;
    sequence: number;
    payLoadCount?: number;
    payloadSizeKb?: number;
    functionColumn: FunctionColumn[];
    setting?: FunctionSetting;
    templateUrl?: String
}
interface currentApp {
    appCode: string,
    functionList:Function[] |any[] | undefined,
    payloadMapGroup: any[] | undefined
    templateUrl: string | undefined
}

interface AppContextValue {
    isLoading: boolean,
    currentApp: currentApp | null;
    conversation: any,
    updateConversation: (currentConversation: any) => void;
    getAppDropDownList: () => selectValue[] | undefined;
    functionDropDownList: () => selectValue[] | undefined;
    getSelectedFunctionItem: (item: string | undefined) => selectValue | undefined;
    getSelectedFunctionColumnsItem: (item: string | undefined) => columnMap[] | undefined;
    onCurrentAppChange: (appCode: string) => void;
    getFunction: (item: string | undefined) => any;
    getMapDropDownList: () => selectValue[] | undefined;
    refreshAppInfo: () => void;
}

const AppContext = createContext<AppContextValue>({
    isLoading: true,
    currentApp: null,
    conversation: null,
    getAppDropDownList: () => { return undefined },
    functionDropDownList: () => { return undefined },
    getSelectedFunctionColumnsItem: (item: string | undefined) => undefined,
    getSelectedFunctionItem: (item: string | undefined) => undefined,
    onCurrentAppChange: (appCode: string) => { },
    updateConversation: (currentConversation: any) => { },
    getFunction: (item: string | undefined) => undefined,
    getMapDropDownList: () => undefined,
    refreshAppInfo: () => { return undefined }
});
let setCurrentApp = (): currentApp | null => {
    return {
        appCode: GetAppId(),
        functionList: undefined,
        payloadMapGroup: undefined,
        templateUrl: undefined
    }
}

export const AppProvider = ({ children }: any) => {
    const apps = useApi('appDtl')
    const navigate = useNavigate()
    let [currentApp, setApp] = useState<currentApp | null>(setCurrentApp())
    const [appDataIsLoading, setAppDataIsLoading] = useState(true)
    useEffect(() => {
        if (apps.data) {
            let currentAppID = GetAppId();
            if (!currentAppID) {
                currentAppID = apps.data?.[0]?.code
                sessionStorage.setItem("appId", currentAppID)
            }
            let app = apps.data?.filter((x: any) => x.code === currentAppID)[0];
            if (app) {
                setApp({
                    appCode: app.code,
                    functionList: app.functionType,
                    payloadMapGroup: app.payloadMapGroup,
                    templateUrl: app.templateUrl
                })
            }
            else {
                setApp(null)
            }
            setAppDataIsLoading(false)
        }
        else {
            setAppDataIsLoading(true)
        }
    }, [apps.data])
    const [conversation, setConversation] = useState<any>(null)
    let getAppDropDownList = (): selectValue[] | undefined => {
        try {
            if (apps.data) {
                return apps.data?.map((item: any) => {
                    return {
                        value: item.code,
                        label: <><img width={25} height={25} src={`${API_URL}/appLogo/${item.image}`}></img><span>{item.name}</span></>
                    } as selectValue
                })
            }
            else {
                return undefined
            }
        }
        catch {
            return undefined
        }

    }
    let onCurrentAppChange = (appCode: string) => {
        try {
            let app = apps.data?.filter((x: any) => x.code === appCode)[0]
            if (app) {
                sessionStorage.setItem("appId", appCode)
                navigate("/conversation")
                setApp({
                    appCode: app.code,
                    functionList: app.functionType,
                    payloadMapGroup: app.payloadMapGroup,
                    templateUrl: app.templateUrl
                })
            }
            else {
                sessionStorage.setItem("appId", '')
                setApp(null)
            }
        }
        catch {
            sessionStorage.setItem("appId", '')
            setApp(null)
        }
    }
    let updateConversation = (currentConversation: any) => {
        setConversation(currentConversation)
    }
    let functionDropDownList = (): selectValue[] | undefined => {
        if (currentApp) {
            try {
                if (currentApp) {
                    return currentApp.functionList?.map<selectValue>((item) => {
                        return {
                            value: item.functionCode,
                            label: item.functionName
                        }
                    })
                }

            }
            catch {

            }

        }
        return undefined
    }
    let getSelectedFunctionItem = (item: string | undefined): selectValue | undefined => {
        if (currentApp) {
            try {
                if (currentApp) {
                    let currentFunction = currentApp.functionList?.filter(x => x.functionCode === item)[0]
                    if (currentFunction) {
                        return {
                            value: currentFunction.functionCode,
                            label: currentFunction.functionName
                        }
                    }
                }
            }
            catch {

            }

        }
        return undefined
    }
    let getSelectedFunctionColumnsItem = (item: string | undefined): columnMap[] | undefined => {
        if (currentApp && item) {
            try {
                if (currentApp) {
                    let currentFunctionColumns: any[] = currentApp.functionList?.filter(x => x.functionCode === item)[0]?.functionColumns
                    if (currentFunctionColumns) {
                        return currentFunctionColumns.map<columnMap>((item) => {
                            return {
                                DestinationCode: item.columnId,
                                DestinationLabel: item.columnLabel,
                                isRequired: item.isRequired,
                                requiredInfo: item.requiredInfo,
                                Source: ""
                            }
                        })
                    }
                }
            }
            catch {

            }

        }
        return undefined
    }
    let getFunction = (item: string | undefined) => {
        if (currentApp) {
            try {
                if (currentApp) {
                    let currentFunction = currentApp.functionList?.filter(x => x.functionCode === item)[0]
                    if (currentFunction) {
                        return {
                            id: currentFunction.id,
                            code: currentFunction.functionCode,
                            label: currentFunction.functionName
                        }
                    }
                }
            }
            catch {

            }

        }
        return undefined

    }
    let getMapDropDownList = () => {

        if (currentApp) {
            try {
                let app = apps.data?.filter((x: any) => x.code === currentApp?.appCode)[0]
                if (app && app.payloadMapGroup) {
                    return app.payloadMapGroup.map((mapItem: any) => {
                        return {
                            value: { id: mapItem.id, payloadMap: mapItem.payloadMap },
                            label: mapItem.mapGroupName
                        }
                    })
                }
            }
            catch {

            }
        }
        return undefined
    }
    let refreshAppInfo = async () => {
        await apps.mutate()
        try {
            let app = apps.data?.filter((x: any) => x.code === currentApp?.appCode)[0]
            if (app) {
                setApp({
                    appCode: app.code,
                    functionList: app.functionType,
                    payloadMapGroup: app.payloadMapGroup,
                    templateUrl: app.templateUrl
                })
                console.log("X call")
            }
        }
        catch {

        }

    }
    return (
        <AppContext.Provider value={{ isLoading: appDataIsLoading, currentApp, getFunction, getMapDropDownList, refreshAppInfo, getSelectedFunctionColumnsItem, conversation, getSelectedFunctionItem, functionDropDownList, getAppDropDownList, onCurrentAppChange, updateConversation }}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    return useContext(AppContext)
}
export const GetAppId = (): string => {
    try {
        const auth = sessionStorage.getItem("appId");
        if (auth) {
            return auth
        }
    }
    catch (error) {
    }
    return '';
}


export function formatDateForApi(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}