import { Button, ButtonGroup, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

import { arrowLeft, arrowRight, close, desktopIcon, searchIcon, tenant } from "../../../constant/icons";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../layout/page";
import { useEffect, useMemo, useState } from "react";
import { ReckonJobFilter } from "./filter";
import ReactDatePicker from "react-datepicker";
import { useApi } from "../../../helper/apiHook";
import { MigrationType, ReckonFunctionColumn } from "./type";
import { Function, useApp } from "../../../components/app";
import { post } from "../../../helper/api";
import { getDate } from "../../../helper/utility";
import { message } from "../../../components/toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { useLoaderHelper } from "../../../helper/loaderHelper";

const ReckonJobList = () => {
  let navigate = useNavigate();
  const app = useApp()
  const loader = useLoaderHelper();
  let [migrationType, setMigrationType] = useState<MigrationType>('Full')
  const [selectedFunction, setSelectedFunction] = useState<number[]>([])
  const [filterModelOpen, setFilterModelOpen] = useState<boolean>(false)
  const toggleFilterModel = () => setFilterModelOpen(!filterModelOpen)
  const JobValidationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required('From date is required')  // Ensures startDate is provided
      .typeError('From date must be a valid date'),  // Ensures the startDate is a valid date
  
    endDate: Yup.date()
      .required('To date is required')  // Ensures endDate is provided
      .typeError('To date must be a valid date')  // Ensures the endDate is a valid date
      .test('is-greater-than-start', 'To date cannot be earlier than From date', function (value) {
        const { startDate } = this.parent;  // Get the startDate from the parent object
        if (startDate && value && new Date(startDate) > new Date(value)) {
          return false;  // If startDate is greater than endDate, validation fails
        }
        return true;  // Validation passes if no issue
      }),
  });
  //------------Here we get all Function List
  const [refreshUI, setRefreshUI] = useState(false)
  const functionList = useMemo(() => {
    return app.currentApp?.functionList?.filter((x: any) => {
      let setting = JSON.parse(x.setting)
      if (migrationType != 'Basic') {
        return true;
      }
      return setting?.isBasicMigration
    }).map((item: any, index) => {

      return <li key={`${migrationType}${index}`}>
        <Input type="checkbox" id={`checkBox${index}`} onChange={(e) => {
          if (e.target.checked && !selectedFunction?.includes(item?.id)) {
            selectedFunction?.push(item?.id)
            setSelectedFunction(selectedFunction)
            setRefreshUI(!refreshUI)
          }
          else if (!e.target.checked) {
            setSelectedFunction(pre => pre?.filter(x => x != item?.id))
            setRefreshUI(!refreshUI)
          }
        }} checked={migrationType != 'Customization' ? true : selectedFunction?.includes(item?.id)} />
        <label htmlFor={`checkBox${index}`}>
          <div>
            <span>{desktopIcon.icon}</span>
            <h6>{item.functionName}</h6>
          </div>
          <div>
            <span>One</span>
            <h6>{item.functionCode}</h6>
          </div>
        </label>
      </li>
    })
  }, [migrationType, selectedFunction.length, refreshUI])
  useEffect(() => {
    setSelectedFunction([])
  }, [migrationType])

  return (<PageLayout
    title={
      <>
        <Button
          color="link"
          onClick={() => navigate("/")}
          className="btn-back"
        >
          {arrowLeft.icon}{" "}
        </Button>
        Function
      </>
    }
    View={
      <>
        <div className="function-migrate-head">
          <ButtonGroup>
            <Button
              color="primary"
              outline
              active={migrationType == 'Full'}
              onClick={() => {
                setMigrationType('Full')
              }}
            >
              Full Migration
            </Button>
            <Button
              color="primary"
              outline
              active={migrationType == 'Basic'}
              onClick={() => {
                setMigrationType('Basic')
              }}
            >
              Basic Migration
            </Button>
            <Button
              color="primary"
              outline
              active={migrationType == 'Customization'}
              onClick={() => {
                setMigrationType('Customization')
              }}
            >
              Customization Migration
            </Button>
          </ButtonGroup>
          <div className="search_box">
            <Button color="link" className="p-0">
              {searchIcon.icon}
            </Button>

            <input
              name="searchbox"
              placeholder="Search.."
              type="search"
              className="top-element"
            />
          </div>
        </div>
        <ul className="migration-card-list">
          {functionList}
        </ul>
        <div className="btn-box maping-btn-box">
          <button className="btn btn-outline-dark" title="Back" type="button">Cancel</button>
          <button className="btn btn-primary" title="Confirm" onClick={() => {
            let allFunc: any[] | undefined = []
            if (migrationType != 'Customization') {
              allFunc = app.currentApp?.functionList?.filter((x: any) => {
                let setting = JSON.parse(x.setting)
                if (migrationType != 'Basic') { return true }
                return setting?.isBasicMigration
              }).map((item: any, index) => item.id)
              if (allFunc) {
                setSelectedFunction(allFunc)
              }
            }
            if ((migrationType != 'Customization' && allFunc && allFunc.length <= 0) || (migrationType == 'Customization' && selectedFunction?.length <= 0)) {
              message('warning', "Please select at least one function for migration.")
              return;
            }

            setFilterModelOpen(true)
          }} type="button">Confirm</button>
        </div>
        {filterModelOpen && <Modal
          toggle={toggleFilterModel}
          isOpen={filterModelOpen}
          scrollable
          centered
          backdrop="static"
          returnFocusAfterClose={false}
          keyboard={false}
          size="lg"
        >
          <ModalHeader toggle={toggleFilterModel}>
            Create New Job:
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                name: "",
                remark: "",
                startDate: null,
                endDate: null,
                jobStartWithSubmit: false,
              }}
              validationSchema={JobValidationSchema}
              onSubmit={async (values) => {
                if (migrationType == 'Customization') {
                  if (selectedFunction?.length <= 0) {
                    message('warning', "Please select at least one function for migration.")
                    return;
                  }
                }
                post("createReckonJob", {...values,
                  tenantId: app.conversation.tenant[0].id,
                  functionList: selectedFunction
                },undefined,loader).then((result) => {
            
                }).catch((error) => {
            
                })
              }}
            >
              {(formProps) => (
                <Form className="mx-0 content-box" onSubmit={formProps.handleSubmit}>
                  <div className="row file-process-header">
                    <FormGroup className="col-6 form-floating ps-0">
                      <Field
                        value={formProps.values.name}
                        className="form-control"
                        name="name"
                        placeholder="Enter Job Name"
                      />
                      <Label>Job Name</Label>
                    </FormGroup>
                    <FormGroup className="col-6 form-floating ps-0">
                      <Field
                        value={formProps.values.remark}
                        className="form-control"
                        name="remark"
                        placeholder="Enter Remark"
                      />
                      <Label>Job Remark</Label>
                    </FormGroup>
                    <FormGroup className="col-6 form-floating sticky_label ps-0">
                      <Field
                        name="startDate"
                        component={ReactDatePicker}
                        placeholderText={"DD/MM/YYYY"}
                        dateFormat={"dd/MM/yyyy"}
                        className="form-control w-100"
                        selected={getDate(formProps.values?.startDate)}
                        onChange={(date: any, _: any) => {
                          formProps.setFieldValue("startDate", date);
                        }}
                      />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-danger"
                      />
                      <Label>
                        From Date<span style={{ color: "red" }}>*</span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="col-6 form-floating ps-0 form-floating sticky_label">
                      <Field
                        name="endDate"
                        component={ReactDatePicker}
                        placeholderText={"DD/MM/YYYY"}
                        dateFormat={"dd/MM/yyyy"}
                        className="form-control w-100"
                        selected={getDate(formProps.values?.endDate)}
                        onChange={(date: any, _: any) => {
                          formProps.setFieldValue("endDate", date);
                        }}
                      />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-danger"
                      />
                      <Label>
                        to Date<span style={{ color: "red" }}>*</span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="col-3 d-flex align-items-center gap-2">
                      <Label className="m-0 job_title_check">
                        <Field type="checkbox" name="jobStartWithSubmit" />
                        Job Start With Submit
                      </Label>
                    </FormGroup>
                  </div>
                  <Button
                    color="primary"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    className="review-submit"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>}
      </>}
  />)
};
export { ReckonJobList };
