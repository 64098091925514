import { isElementAccessExpression } from "typescript";
import { get } from "../../helper/api";
export const requiredMarks = "<span style={{ color:'red'}}>*</span>";
export const connectToAPP = (url: string, data: any, loader: any) => {

  get(url,data,loader)
    .then((response) => {
      if (response && response?.data.length > 0) {
         console.log("xeroSS",response)
        if (data.loginType == "xero" || data.loginType == "QBO") {
          window.location.href = response?.data[0];
        } else {
          window.open(response?.data[0], "_blank");
        }

      }
    })
    .catch(() => { });
};
